import { Form, Formik, FormikErrors, useFormikContext } from 'formik';
import { kebabCase } from 'lodash';
import { Plus } from 'lucide-react';
import { useRouter } from '@uirouter/react';

import { useCurrentEnvironment } from '@/react/hooks/useCurrentEnvironment';
import { notifySuccess } from '@/portainer/services/notifications';
import {
  formatMachineOptions,
  MachineSetNodeSelect,
} from '@/react/kubernetes/cluster/omni/components/MachineSetNodeSelect';
import { transformMachineValuesToMachinePayload } from '@/react/portainer/environments/wizard/EnvironmentsCreationView/WizardK8sInstall/omni/OmniCreateClusterForm';

import { WidgetBody } from '@@/Widget';
import { FormControl } from '@@/form-components/FormControl';
import { FormSection } from '@@/form-components/FormSection';
import { FormActions } from '@@/form-components/FormActions';
import { isArrayErrorType } from '@@/form-components/formikUtils';

import {
  OmniClusterMachineSet,
  OmniMachineFormValues,
  OmniMachinePayload,
} from '../../omni/types';
import { UpdateOmniClusterPayload } from '../../HomeView/omni/types';
import { useTalosMachines } from '../../omni/queries/useTalosMachines';
import { useUpdateCluster } from '../../omni/queries/useUpdateCluster';
import { useOmniCluster } from '../../omni/queries/useOmniCluster';
import { OmniAddNodesFormValues } from '../types';

import { omniAddNodesFormValidation } from './omniAddNodesFormValidation';

const initialValues: OmniAddNodesFormValues = {
  machineSets: [
    {
      name: 'Control Plane',
      role: 'control-plane',
      machines: [],
    },
    {
      name: 'Main worker pool',
      role: 'worker',
      machines: [],
    },
  ],
};

export function OmniAddNodesForm() {
  const environmentQuery = useCurrentEnvironment();
  const environment = environmentQuery.data;
  const credentialId = environment?.CloudProvider?.CredentialID;
  const updateClusterMutation = useUpdateCluster(credentialId);
  const router = useRouter();

  return (
    <WidgetBody>
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={omniAddNodesFormValidation}
        validateOnMount
        enableReinitialize
      >
        <OmniAddNodesInnerForm
          credentialId={credentialId}
          environmentName={environment?.Name}
        />
      </Formik>
    </WidgetBody>
  );

  async function handleSubmit(values: OmniAddNodesFormValues) {
    const controlPlaneMachines: OmniMachinePayload[] =
      values.machineSets
        .find((machineSet) => machineSet.role === 'control-plane')
        ?.machines?.map(transformMachineValuesToMachinePayload) ?? [];
    const workerMachines: OmniMachinePayload[] =
      values.machineSets
        .find((machineSet) => machineSet.role === 'worker')
        ?.machines?.map(transformMachineValuesToMachinePayload) ?? [];
    const updateClusterPayload: UpdateOmniClusterPayload = {
      cluster: {
        kind: 'Cluster',
        name: environment?.Name ?? '',
      },
      controlPlanesToAdd: {
        kind: 'ControlPlane',
        machines: controlPlaneMachines,
      },
      workersToAdd: {
        kind: 'Workers',
        machines: workerMachines,
      },
    };
    await updateClusterMutation.mutateAsync(updateClusterPayload, {
      onSuccess: () => {
        notifySuccess(
          'Success',
          'Request to add nodes successfully submitted. This might take a few minutes to complete.'
        );
        router.stateService.go('kubernetes.cluster');
      },
    });
  }
}

function OmniAddNodesInnerForm({
  environmentName,
  credentialId,
}: {
  environmentName?: string;
  credentialId?: number;
}) {
  const { values, setFieldValue, isSubmitting, errors, isValid } =
    useFormikContext<OmniAddNodesFormValues>();
  const machineSetErrors = getMachinesErrors(errors?.machineSets);
  const clusterQuery = useOmniCluster(environmentName ?? '', credentialId, {
    enabled: !!environmentName,
  });
  const talosVersion = clusterQuery.data?.metadata.talos_version;
  const machineOptionsQuery = useTalosMachines({
    queryOptions: {
      select: (machines) => ({
        options: formatMachineOptions(machines),
        machines,
      }),
    },
    params: {
      isAvailable: 'true',
    },
  });
  const machineOptions = machineOptionsQuery.data?.options ?? [];
  const selectedMachineCount = values.machineSets.reduce(
    (acc, machineSet) => acc + machineSet.machines.length,
    0
  );

  if (!credentialId || !talosVersion) {
    return null;
  }

  return (
    <Form className="form-horizontal">
      <FormSection title="Add nodes to cluster">
        {values.machineSets.map((machineSet, index) => (
          <FormControl
            key={machineSet.name}
            label={machineSet.name}
            inputId={kebabCase(machineSet.name)}
            tooltip={
              machineSet.role === 'control-plane'
                ? 'Control plane nodes manage cluster state and workload scheduling on worker nodes. For high availability, use 3 nodes (or 5 for greater reliability).'
                : undefined
            }
          >
            <MachineSetNodeSelect
              talosVersion={talosVersion}
              values={values.machineSets[index].machines}
              errors={machineSetErrors?.[index]}
              onChange={(machines: OmniMachineFormValues[]) => {
                setFieldValue(`machineSets[${index}].machines`, machines);
              }}
              options={machineOptions}
              machineSetRole={values.machineSets[index].role}
              machineSetValues={values.machineSets}
              data-cy={`microk8sCreateForm-workerNodesInput${index}`}
              credentialId={credentialId}
            />
          </FormControl>
        ))}
      </FormSection>
      <FormActions
        submitLabel="Add nodes"
        loadingText="Adding nodes..."
        submitIcon={Plus}
        isLoading={isSubmitting}
        isValid={selectedMachineCount > 0 && isValid}
        data-cy="add-omni-nodes-button"
      />
    </Form>
  );
}

// getMachinesErrors filters the errors type so it can be passed as a clean prop to MachineSetNodeSelect
function getMachinesErrors(
  machineSetsErrors?: string | string[] | FormikErrors<OmniClusterMachineSet>[]
) {
  const machineErrors = isArrayErrorType(machineSetsErrors)
    ? machineSetsErrors
    : undefined;
  if (!machineErrors) {
    return undefined;
  }
  const machinesErrors = machineErrors.map((machineSetErrors) =>
    isArrayErrorType(machineSetErrors?.machines)
      ? machineSetErrors.machines
      : undefined
  );
  return machinesErrors;
}
