import _ from 'lodash';
import { useFormikContext } from 'formik';
import { useEffect } from 'react';

import { Environment } from '@/react/portainer/environments/types';
import { semverCompare } from '@/react/common/semver-utils';
import { useSystemStatus } from '@/react/portainer/system/useSystemStatus';

import { TextTip } from '@@/Tip/TextTip';

import { FormValues } from './types';
import { ScheduledTimeField } from './ScheduledTimeField';

/**
 * in-case agents don't have any version field, it means they are version less then 2.15.x or that they still not associated.
 */
const DEFAULT_MIN_VERSION = '2.14.10';

interface Props {
  environments: Environment[];
  hasTimeZone: boolean;
  hasNoTimeZone: boolean;
  hasGroupSelected: boolean;
}

export function UpdateScheduleDetailsFieldset({
  environments,
  hasTimeZone,
  hasNoTimeZone,
  hasGroupSelected,
}: Props) {
  const {
    values: { version },
    setFieldValue,
  } = useFormikContext<FormValues>();

  const minVersion = _.first(
    _.compact<string>(environments.map((env) => env.Agent.Version)).sort(
      (a, b) => semverCompare(a, b)
    )
  );

  const statusQuery = useSystemStatus();

  useEffect(() => {
    if (statusQuery?.data?.Version && version !== statusQuery.data.Version) {
      setFieldValue('version', statusQuery.data.Version);
    }
  }, [setFieldValue, statusQuery?.data?.Version]);

  return (
    <>
      <EnvironmentInfo
        environments={environments}
        version={version}
        miniVersion={minVersion}
      />

      {hasTimeZone && hasGroupSelected && <ScheduledTimeField />}
      {hasNoTimeZone && (
        <TextTip inline={false}>
          These edge groups have older versions of the edge agent that do not
          support scheduling, these will happen immediately
        </TextTip>
      )}
    </>
  );
}

function EnvironmentInfo({
  environments,
  version,
  miniVersion,
}: {
  environments: Environment[];
  version: string;
  miniVersion?: string;
}) {
  if (!environments.length) {
    return (
      <TextTip color="orange">
        No environments options for the selected edge groups
      </TextTip>
    );
  }

  if (!version) {
    return null;
  }

  if (semverCompare(version, miniVersion || DEFAULT_MIN_VERSION) < 0) {
    return <TextTip>No supported versions available</TextTip>;
  }

  const environmentsAlreadyOnVersion = environments.filter(
    (env) =>
      !env.Agent.Version || semverCompare(version, env.Agent.Version) <= 0
  ).length;

  const environmentsToUpdate =
    environments.length - environmentsAlreadyOnVersion;

  if (environmentsToUpdate === 0) {
    return (
      <TextTip color="orange">
        All edge agents are already running version {version}
      </TextTip>
    );
  }

  return (
    <TextTip color="blue">
      {environmentsAlreadyOnVersion > 0 && (
        <>
          {environmentsAlreadyOnVersion} edge agent
          {environmentsAlreadyOnVersion > 1 ? 's are' : ' is'} currently running
          version greater than or equal to {version}, and{' '}
        </>
      )}
      {environmentsToUpdate > 0 && (
        <>
          {environments.length - environmentsAlreadyOnVersion} will be updated
          to version {version}
        </>
      )}
    </TextTip>
  );
}
