import { useMutation, useQueryClient } from '@tanstack/react-query';

import axios, { parseAxiosError } from '@/portainer/services/axios';
import { getAllSettledItems } from '@/portainer/helpers/promise-utils';
import { notifyError, notifySuccess } from '@/portainer/services/notifications';
import { pluralize } from '@/portainer/helpers/strings';

import { queryKeys } from './query-keys';

export function useRebootOmniNode(cluster: string, credentialId?: number) {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (nodeNames: string[]) =>
      rebootOmniNodes(nodeNames, cluster, credentialId),
    onSuccess: ({ fulfilledItems, rejectedItems }) => {
      // one error notification per rejected item
      rejectedItems.forEach(({ item, reason }) => {
        notifyError(`Failed to reboot node '${item}'`, new Error(reason));
      });

      // one success notification for all fulfilled items
      if (fulfilledItems.length) {
        notifySuccess(
          `${pluralize(
            fulfilledItems.length,
            'Request to reboot node'
          )} successfully received`,
          fulfilledItems.map((item) => item).join(', ')
        );
      }
      queryClient.invalidateQueries(queryKeys.machines());
    },
  });
}

async function rebootOmniNodes(
  nodeNames: string[],
  cluster: string,
  credentialId?: number
) {
  if (!credentialId) {
    throw new Error('Credential ID is required');
  }

  return getAllSettledItems(nodeNames, rebootOmniNode);

  async function rebootOmniNode(nodeName: string) {
    try {
      await axios.post(`/omni/${credentialId}/node/${nodeName}`, undefined, {
        params: { cluster },
      });
    } catch (error) {
      throw parseAxiosError(error);
    }
  }
}
