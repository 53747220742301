import { StatusType } from '../types';

export const queryKeys = {
  base: ['omni'],
  clusters: () => [...queryKeys.base, 'cluster'],
  cluster: (name?: string) => [...queryKeys.base, 'cluster', name ?? ''],
  clusterStatus: (statusType: StatusType, name?: string) => [
    ...queryKeys.base,
    'cluster',
    name ?? '',
    'status',
    statusType,
  ],
  talosVersions: (credentialId?: number) => [
    ...queryKeys.base,
    'talosVersions',
    credentialId,
  ],
  machines: (params?: Record<string, string>) => [
    ...queryKeys.base,
    'machines',
    params,
  ],
  machine: (name: string, params?: Record<string, string>) => [
    ...queryKeys.base,
    'machine',
    name,
    params,
  ],
  machineLogs: (name?: string) => [...queryKeys.base, 'machine', name, 'logs'],
};
