import { FormikErrors, FormikHandlers } from 'formik';

import { EdgeGroup } from '@/react/edge/edge-groups/types';
import { useEdgeGroups } from '@/react/edge/edge-groups/queries/useEdgeGroups';

import { FormControl } from '@@/form-components/FormControl';
import { Select } from '@@/form-components/ReactSelect';

import { EnvironmentType } from '../../types';

import { FormValues } from './types';

interface Props {
  disabled?: boolean;
  onBlur: FormikHandlers['handleBlur'];
  value: FormValues['groupIds'];
  error?: FormikErrors<FormValues>['groupIds'];
  onChange(value: FormValues['groupIds']): void;
}

export function EdgeGroupsField({
  disabled,
  onBlur,
  value,
  error,
  onChange,
}: Props) {
  const groupsQuery = useEdgeGroups();

  const selectedGroups = groupsQuery.data?.filter((group) =>
    value.includes(group.Id)
  );

  let errorMessage = error;
  if (selectedGroups && selectedGroups.length >= 1) {
    errorMessage = validateMultipleGroups(selectedGroups);
  }

  return (
    <div>
      <FormControl
        label="Edge Groups"
        required
        inputId="groups-select"
        errors={errorMessage}
        tooltip="Updates are done based on groups, allowing you to choose multiple devices at the same time and the ability to roll out progressively across all environments by scheduling them for different days."
      >
        <Select
          name="groupIds"
          onBlur={onBlur}
          value={selectedGroups}
          inputId="groups-select"
          placeholder="Select one or multiple group(s)"
          onChange={(selectedGroups) =>
            onChange(selectedGroups.map((g) => g.Id))
          }
          isMulti
          options={groupsQuery.data || []}
          getOptionLabel={(group) => group.Name}
          getOptionValue={(group) => group.Id.toString()}
          closeMenuOnSelect={false}
          isDisabled={disabled}
          data-cy="update-schedules-edge-groups-select"
        />
      </FormControl>
    </div>
  );
}

function validateMultipleGroups(groups: EdgeGroup[]) {
  let uniqueEndpointType = EnvironmentType.Docker;

  for (let i = 0; i < groups.length; i++) {
    for (let j = 0; j < groups[i].EndpointTypes.length; j++) {
      if (i === 0 && j === 0) {
        uniqueEndpointType = groups[i].EndpointTypes[j];
      }

      if (uniqueEndpointType !== groups[i].EndpointTypes[j]) {
        return 'Please select edge group(s) that have edge environments of the same type.';
      }
    }
  }

  return '';
}
